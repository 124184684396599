import React, { useEffect } from "react";
import {
  ActiveParticipantsContainer,
  MentorHeading,
  MentorHeader,
  Info,
  Image,
  Name,
  Actions,
  Meta,
  MenteeHeader,
  ParticipantsContainer,
  UnassignedList,
  UnassignedContainer,
  ViewChatIconContainer,
  UnassignedActionContainer,
  ActiveParticipantsWrapper,
  ParticipantsContainerInner
} from "./styles";
import { useNavigate, useParams } from "react-router-dom";
import callAPI from "../../../../../../api/apiCaller";
import Accordian from "../../../../../../common/Accordian";
import Button from "../../../../../../common/Button";
import { ButtonSpacer } from "../../../../../../common/Button/styles";
import Loading from "../../../../../../common/Loading";
import ReassignMentee from "../../../popups/ReassignMentee";
import { Context } from "../../../../../../Store";
import UserInfo from "../../../popups/UserInfo";
import TeamParticipant from "../../../TeamParticipant";
import Modal from "../../../../../../common/Modal";
import { SectionActions } from "../../../../Profile/LoginSecurity/styles";
import SpecificTeam from "../../MyTeams/SpecificTeam";
import MyTeams from "../../MyTeams";

function ActiveParticipants({
  searchText,
  specificTeamProgram,
  setSpecificTeamProgram,
}) {
  React.useEffect(() => {
    programInformation();
  }, []);

  const params = useParams();
  const navigate = useNavigate();
  const [mentorParticipants, setMentorParticipants] = React.useState(null);
  const [mentorSearchParticipants, setMentorSearchParticipants] = React.useState(null);
  const [participants, setParticipants] = React.useState(null);
  const [isReassignOpen, setIsReassignOpen] = React.useState(false);
  const [isReassignOpenTeam, setIsReassignOpenTeam] = React.useState(false);
  const [isViewOpen, setIsViewOpen] = React.useState(false);
  const [refreshParticipants, setRefreshParticipants] = React.useState(false);
  const [isDeleteMember, setIsDeletMember] = React.useState(false);
  const [program, setProgram] = React.useState([]);
  const [teams, setTeams] = React.useState([]);
  const [pageSize, setPageSize] = React.useState(20);
  const [loading, setLoading] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(1);

  const [pageSizeUnAssigned, setPageSizeUnAssigned] = React.useState(20);
  const [loadingUnAssigned, setLoadingUnAssigned] = React.useState(false);
  const [pageUnAssigned, setPageUnAssigned] = React.useState(1);
  const [totalPagesUnAssigned, setTotalPagesUnAssigned] = React.useState(1);

  const Participants = (data) => {
    let activeParticipants = [];

    data?.unAssignedMentees
      ? (activeParticipants = [
          { name: "unAssignedMentees", mentees: data?.unAssignedMentees },
        ])
      : (activeParticipants = [
          { name: "unAssignedMentees", mentees: data?.unAssignedMembers },
        ]);

    return activeParticipants;
  };

  const { state, dispatch } = React.useContext(Context);

  const programInformation = () => {
    callAPI(dispatch, "getProgramInformation", {
      id: params.selectedProgram,
    }).then((info) => {
      setProgram(info.data);
    });
  };

  const getParticipant = () => {
    callAPI(dispatch, "getUnAssignedProgramParticipants", {
      id: params.selectedProgram,
      page: pageUnAssigned,
      take: pageSizeUnAssigned,
    }).then((res) => {
      if(res){
      setParticipants(Participants(res.data.active));
      setTotalPagesUnAssigned(res.data.totalPages);
      }
    });
  };

  const getMentorParticipant = () => {
    callAPI(dispatch, "getMentorProgramParticipants", {
      id: params.selectedProgram,
      page: page, // Use updated page value
      take: pageSize,
    }).then((res) => {
      if(res){
        setMentorParticipants(res.data.active);
        setTotalPages(res.data.totalPages);
      }
    });

    callAPI(dispatch, "getSearchMentorProgramParticipants", {
      id: params.selectedProgram,
    }).then((res) => {
      if(res){
        setMentorSearchParticipants(res.data.active);
      }
    });
  };

  function getMyTeam() {
    callAPI(dispatch, "getMyteams", {
      id: params.selectedProgram,
    }).then((res) => {
      setTeams(res?.data);
    });
  }

  function deleteProgram(id) {
    callAPI(dispatch, "DeleteMember", {
      tid: +params.selectedProgram,
      id: id,
    }).then(() => {
      setIsDeletMember(false);
      getParticipant();
      getMentorParticipant();
    });
  }

  const handleScrollUnAssigned = async (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    if (scrollTop + clientHeight + 3 >= scrollHeight && !loadingUnAssigned && pageUnAssigned < totalPagesUnAssigned) {
      setLoadingUnAssigned(true);
      setPageUnAssigned(prevPage => prevPage + 1); // Update page using a callback
      callAPI(dispatch, "getUnAssignedProgramParticipants", {
        id: params.selectedProgram,
        page: pageUnAssigned + 1, // Use updated page value
        take: pageSizeUnAssigned,
      }).then((res) => {
        if(res?.data?.active?.unAssignedMentees){
          const updatedState = participants.map(participant => {
            if (participant.name === "unAssignedMentees") {
              return {
                ...participant,
                mentees: [...participant.mentees, ...res.data.active.unAssignedMentees]
              };
            }
            return participant;
          });
          setParticipants(updatedState);
        } else {
          const updatedState = participants.map(participant => {
            if (participant.name === "unAssignedMentees") {
              return {
                ...participant,
                mentees: [...participant.mentees, ...res.data.active.unAssignedMembers]
              };
            }
            return participant;
          });
          setParticipants(updatedState);
        }
        setLoadingUnAssigned(false);
      });
    }
  };


  const handleScroll = async (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    if (scrollTop + clientHeight + 3 >= scrollHeight && !loading && page < totalPages) {
      setLoading(true);
      setPage(prevPage => prevPage + 1); // Update page using a callback
      callAPI(dispatch, "getMentorProgramParticipants", {
        id: params.selectedProgram,
        page: page + 1, // Use updated page value
        take: pageSize,
      }).then((res) => {
      setMentorParticipants(prevState => ({
          mentors: [
            ...prevState.mentors,
            ...res.data.active.mentors
          ]
        }));
        setLoading(false);
      });
    }
  };



  React.useEffect(() => {
    getMyTeam();
    setParticipants(null);
    setMentorParticipants(null);
    getParticipant();
    getMentorParticipant();
  }, [refreshParticipants]);

  const filteredMentors = searchText !== ''
  ? mentorSearchParticipants?.mentors?.filter((mentor) => {
      // Exclude unassigned mentees
      if (mentor?.name === "unAssignedMentees") {
        return false;
      }
      if (
        mentor.participant.firstName
          .toLowerCase()
          .includes(searchText.toLowerCase())
      ) {
        return true;
      }
      if (
        mentor.mentees.length &&
        mentor.mentees?.some((mentee) =>
          mentee.participant.firstName
            ?.toLowerCase()
            .includes(searchText.toLowerCase())
        )
      ) {
        return true;
      }

      return false; 
    })
  : mentorParticipants?.mentors;

  if (mentorParticipants === null) {
    return (
      <ActiveParticipantsContainer>
        <Loading loadingItem="Participants" />
      </ActiveParticipantsContainer>
    );
  }

  if (participants === null) {
    return (
      <ActiveParticipantsContainer>
        <Loading loadingItem="Participants" />
      </ActiveParticipantsContainer>
    );
  }

  return (
    <ActiveParticipantsWrapper>
      <ActiveParticipantsContainer>
        {program && program?.programType === "TEAM" ? (
          <ParticipantsContainer isTeam={true} >
            <TeamParticipant
              searchText={searchText}
              setSpecificProgram={setSpecificTeamProgram}
              specificTeamProgram={specificTeamProgram}
            />
          </ParticipantsContainer>
        ) : (
          <ParticipantsContainer>
            <MentorHeading>
              {program?.programType === "OPEN_NETWORK"
                ? "Meetings that have Occurred"
                : "Assigned Mentors and Mentees"}
            </MentorHeading>
            <ButtonSpacer />
            <ButtonSpacer />
            <ParticipantsContainerInner onScroll={handleScroll}>
              <Accordian
                multiOpen={true}
                defaultOpen={false}
                height={false}
                sections={filteredMentors && filteredMentors?.map((mentor) => {
                    return {
                      title: (
                        <MentorHeader>
                          <Info>
                            {
                              <Image
                                src={
                                  mentor.participant.image
                                    ? mentor.participant.image
                                    : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                                }
                              />
                            }
                            <Name>
                              {`${mentor?.participant.firstName} ${mentor?.participant.lastName}`}
                              <Meta>Mentor</Meta>
                            </Name>
                          </Info>
                          {mentor.participant.firstName !=
                            "unAssignedMentees" && (
                            <Actions>
                              <Meta>
                                Total Tracked Hours : {mentor.totalTrackHours} |
                                Total Mentees: {mentor.mentees.length}
                              </Meta>
                              <ButtonSpacer />
                              <ViewChatIconContainer>
                                <Button
                                  icon="view"
                                  onClick={() => {
                                    setIsViewOpen(mentor.participantId);
                                  }}
                                />
                                <ButtonSpacer />
                                <Button
                                  icon="chat"
                                  onClick={() => {
                                    navigate(`/messages/${mentor.participant.uuid}`);
                                    callAPI(dispatch, "initializeConversation", { id: mentor?.participant.id });
                                  }}
                                />
                              </ViewChatIconContainer>
                            </Actions>
                          )}
                        </MentorHeader>
                      ),
                      content: (
                        <>
                          {mentor.mentees.length !== 0 &&
                            mentor.mentees
                              .filter((mentee) => {
                                if (
                                  mentor.participant.firstName
                                    .toLowerCase()
                                    .includes(searchText.toLowerCase())
                                ) {
                                  return true;
                                }
                                if (
                                  mentee.participant.firstName
                                    .toLowerCase()
                                    .includes(searchText.toLowerCase())
                                ) {
                                  return true;
                                }
                              })
                              .map((mentee) => {
                                return (
                                  <MenteeHeader>
                                    <Info>
                                      {
                                        <Image
                                          src={
                                            mentee.participant.image
                                              ? mentee.participant.image
                                              : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                                          }
                                        />
                                      }
                                      <Name>
                                        {mentee.participant.firstName +
                                          " " +
                                          mentee.participant.lastName}
                                        <Meta>Mentee</Meta>
                                      </Name>
                                    </Info>
                                    <Actions>
                                      <Meta>
                                        Tracked Hours : {mentee.trackedHours}
                                      </Meta>
                                      <ButtonSpacer />
                                      <ViewChatIconContainer>
                                        <Button
                                          icon="view"
                                          onClick={() => {
                                            setIsViewOpen(mentee.participantId);
                                          }}
                                        />
                                        <ButtonSpacer />
                                        <Button
                                          icon="chat"
                                          onClick={() => {
                                            navigate(
                                              `/messages/${mentee.participant.uuid}`
                                            );
                                            callAPI(dispatch, "initializeConversation", { id: mentee?.participant.id });
                                          }}
                                        />

                                        <ButtonSpacer />
                                        {program &&
                                          program?.programType ===
                                            "1_1_NETWORK" && (
                                            <Button
                                              text="Reassign"
                                              variant="primary"
                                              onClick={() => {
                                                setIsReassignOpen(
                                                  mentee.participantId
                                                );
                                              }}
                                            />
                                          )}
                                      </ViewChatIconContainer>
                                    </Actions>
                                  </MenteeHeader>
                                );
                              })}
                        </>
                      ),
                    };
                  })}
              />
              {loading && <Loading loadingItem="participants" isLoading={true}/>}
            </ParticipantsContainerInner>

          </ParticipantsContainer>
        )}
        <UnassignedContainer onScroll={handleScrollUnAssigned}>
          <MentorHeading>
            {program.programType === "OPEN_NETWORK"
              ? "All Mentees"
              : program.programType === "TEAM"
              ? "Unassigned Member"
              : "Unassigned Mentees"}
          </MentorHeading>
          <ButtonSpacer />
          <UnassignedList>
            {participants && participants
              .filter((mentor) => {
                if (mentor.name === "unAssignedMentees") {
                  return true;
                }
                return false;
              })
              .map((mentor) => {
                return (
                  <>
                    {mentor?.mentees?.length !== 0 &&
                      mentor?.mentees
                        .filter((mentee) => {
                            if (program.programType !== "TEAM" && 
                              mentee?.participant?.firstName
                              .toLowerCase()
                              .includes(searchText.toLowerCase())){
                              return true;
                            } else return true;
                        })
                        .map((mentee) => {
                          return (
                            <MenteeHeader>
                              <Info>
                                {
                                  <Image
                                    src={
                                      mentee?.participant?.image
                                        ? mentee?.participant?.image
                                        : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                                    }
                                  />
                                }
                                <Name>
                                  {mentee?.participant?.firstName +
                                    " " +
                                    mentee?.participant?.lastName}
                                  <Meta>
                                    {program.programType === "TEAM"
                                      ? ""
                                      : "Mentee"}
                                  </Meta>
                                </Name>
                              </Info>
                              <UnassignedActionContainer>
                                <ButtonSpacer />
                                <Button
                                  icon="view"
                                  onClick={() => {
                                    setIsViewOpen(mentee?.participantId);
                                  }}
                                />
                                <ButtonSpacer />
                                <Button
                                  icon="chat"
                                  onClick={() => {
                                    navigate(
                                      `/messages/${mentee?.participant.uuid}`
                                    );
                                    callAPI(dispatch, "initializeConversation", { id: mentee?.participant.id });
                                  }}
                                />
                                <ButtonSpacer />
                                <Button
                                  icon="trash"
                                  onClick={() => {
                                    setIsDeletMember(mentee?.participantId);
                                  }}
                                />
                                <ButtonSpacer />
                                {program &&
                                  program?.programType === "1_1_NETWORK" && (
                                    <>
                                      <Button
                                        text="Assign"
                                        variant="primary"
                                        onClick={() => {
                                          setIsReassignOpen(
                                            mentee?.participantId   
                                          );
                                        }}
                                      />
                                    </>
                                  )}
                                {program && program?.programType === "TEAM" && (
                                  <>
                                    <Button
                                      text="Assign"
                                      variant="primary"
                                      onClick={() => {
                                        setIsReassignOpenTeam(
                                          mentee?.participantId
                                        );
                                      }}
                                    />
                                  </>
                                )}
                              </UnassignedActionContainer>
                            </MenteeHeader>
                          );
                        })}
                  </>
                );
              })}
              {loadingUnAssigned && <Loading loadingItem="participants" isLoading={true}/>}
          </UnassignedList>
        </UnassignedContainer>

        {isReassignOpen && (
          <ReassignMentee
            menteeId={isReassignOpen}
            closeModal={(isRefresh) => {
              if (isRefresh) {
                setRefreshParticipants(!refreshParticipants);
              }
              setIsReassignOpen(false);
            }}
          />
        )}

        {isReassignOpenTeam && (
          <ReassignMentee
            program={program}
            team={teams}
            getMyTeam={getMyTeam}
            programInformation={programInformation}
            menteeId={isReassignOpenTeam}
            closeModal={(isRefresh) => {
              if (isRefresh) {
                setRefreshParticipants(!refreshParticipants);
              }
              setIsReassignOpenTeam(false);
            }}
          />
        )}

        {isDeleteMember && (
          <Modal
            title={"Are you sure you want to remove member"}
            onClose={() => setIsDeletMember(false)}
          >
            <SectionActions>
              <Button
                icon={"trash"}
                variant="danger"
                onClick={() => {
                  deleteProgram(isDeleteMember);
                }}
                text="Yes"
              />
              <ButtonSpacer />
              <Button
                // icon="tick"
                variant="primary"
                onClick={() => setIsDeletMember(false)}
                text="Cancel"
              />
            </SectionActions>
          </Modal>
        )}

        {isViewOpen && (
          <UserInfo
            userId={isViewOpen}
            closeModal={() => {
              setIsViewOpen(false);
            }}
          />
        )}
      </ActiveParticipantsContainer>
    </ActiveParticipantsWrapper>
  );
}
export default ActiveParticipants;
