import React, { useCallback } from "react";
import {
  ProgramContainer,
  Header,
  Section,
  Title,
  Body,
  Image,
  Form,
  Avatar,
  BookingHeading,
  NoprogramContainer,
  RequestsKeyHeaders,
  ButtonsWraper,
  TableContainerOuter,
  AvatarInfo
} from "./styles";
import callAPI from "../../../../api/apiCaller";
import { Context } from "../../../../Store";
import Button from "../../../../common/Button";
import { Link, useNavigate } from "react-router-dom";
import Loading from "../../../../common/Loading";
import { ButtonSpacer, SectionActions } from "../../../../common/Button/styles";
import Labelize from "../../../../common/Labelize";
import {
  NumberOfDaysBetweenDates,
  NumberOfDaysBetweenDatesAndCurrentDate,
  dateToUnixTimeStamp,
  formatDate,
  formatDescription,
} from "../../../../common/utils";
import Table from "../../../../common/Table";
import { TableActions } from "../../../../common/Table/styles";
import { Info } from "../../Programs/ProgramContent/ProgramParticipants/ActiveParticipants/styles";
import { useParams } from "react-router-dom";
import Modal from "../../../../common/Modal";
import { refreshProgramList } from "../../../../Store/ProgramsList/actions";
import UserInfo from "../../Programs/popups/UserInfo";
import MeetingInfo from "../../Programs/popups/MeetingInfo";
import { getProgramsList } from "../../../../Store/ProgramsList/accessors";



function ActivityContent({ currentPage, searchParticipant }) {
  const params = useParams();
  const [requests, setRequests] = React.useState(null);
  const { dispatch ,state } = React.useContext(Context);
  const navigate = useNavigate();
  const [isDeleteModal, setIsDeleteModal] = React.useState(false);
  const [isDeleteRequest, setIsDeleteRequest] = React.useState(false);
  const [isViewMore, setIsviewMore] = React.useState(false);
  const [program, setProgram] = React.useState(false);
  
  React.useEffect(() => {
    callAPI(
      dispatch,
      currentPage === "UPCOMING"
        ? "ProgramNewRequestForManager"
        : currentPage === "COMPLETE"
        ? "ProgramCompleteRequestForManager"
        : "ProgramUnFullFiledRequestForManager",
      { id: params.selectedProgram }
    ).then((res) => {
      if (res.isResponseOk) {
        setRequests(res.data);
      } else setRequests([]);
    });
    return () => {
      setRequests(null);
    };
  }, [currentPage]);
  let url = window.location.protocol + "//" + window.location.host + "/programs/"+program?.id+"/home";
  const filterParticipant = requests?.filter((item) => {
    if (
      (item?.user?.firstName && item?.user?.firstName) ||
      (item?.user?.lastName && item?.user?.lastName)
    ) {
      return (
        item?.user?.firstName
          .toLowerCase()
          .includes(searchParticipant.toLowerCase()) ||
        item?.user?.lastName
          .toLowerCase()
          .includes(searchParticipant.toLowerCase())
      );
    }
    return false;
  });
  function removeItemFromArray(itemToRemove) {
    const updatedArray = requests.filter((item) => item.id !== itemToRemove);
    setRequests(updatedArray);
  }

  
  function deleteProgram(meeting) {
    setIsDeleteRequest(true);
    callAPI(dispatch, "deleteMeetingRequest", {
      rid:meeting.id,
      id: meeting.id,
      tid:meeting.programId
    }).then(() => {
      setIsDeleteRequest(false);
      removeItemFromArray(meeting.id);
      setIsDeleteModal(false);
    });
  }

  const programInformation =() => {
    callAPI(dispatch, "getProgramInformation", {
      id: params.selectedProgram,
    }).then((info) => {
      setProgram(info.data);
    });
  }

  React.useEffect(()=>{
    programInformation()
   },[])


  if (requests === null) {
    return (
      <ProgramContainer>
        <Loading loadingItem="Activity Details" />
      </ProgramContainer>
    );
  }

  const closeModal = () => {
    setIsviewMore(false);
  };

  return (
    <ProgramContainer>
      <Body>
        {/* <Section> */}

        {/* <Section>
          <Image src={program.image} />
        </Section> */}
        {/* </Section> */}

        <ButtonSpacer />
        <ButtonSpacer />
        <TableContainerOuter>
        <Labelize label={""}>
          <BookingHeading>
            {currentPage === "UPCOMING"
              ? "New Bookings"
              : currentPage === "COMPLETE"
              ? "Completed Bookings"
              : "Unaccepted Requests"}{" "}
          </BookingHeading>

          {filterParticipant.length === 0 ? (
            <NoprogramContainer>
              <RequestsKeyHeaders size={13} widthSize={300} marginSpace={30}>
              <Link to={url}>  {program?.title ? program?.title :"Program"}</Link>{"  "}
                does not have any   {currentPage === "UPCOMING"
              ? "New Bookings"
              : currentPage === "COMPLETE"
              ? "Completed Bookings"
              : "Unaccepted Requests"}{" "}.
              </RequestsKeyHeaders>
              {/* <ImagePreviews src={NoProgramImage} /> */}
              
            </NoprogramContainer>
          ) : (
            <Table
              columns={[
                // {
                //   key: "avatar",
                //   render: ({ row }) => (
                //     <Info>
                //       {
                //         <Avatar
                //         leftSpace={1}
                //           src={
                //             row?.user?.image
                //               ? row?.user?.image
                //               : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                //           }
                //         />
                //       }
                //     </Info>
                //   ),
                // },
                {
                  key: "name",
                  title: "Requested By",
                  sortable: true,
                  render: ({ row }) => {
                    return (
                    <AvatarInfo>
                      <Info>
                        {
                          <Avatar
                          leftSpace={1}
                            src={
                              row?.user?.image
                                ? row?.user?.image
                                : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                            }
                          />
                        }
                      </Info>
                      <p>{row?.user.firstName + " " + row?.user.lastName}</p>
                    </AvatarInfo>
                  )},
                },
                {
                  key: "reasonForMeeting",
                  title: "Purpose",
                  sortable: true,
                  render: ({ row }) => {
                    return row?.reasonForMeeting;
                  },
                },

                {
                  key: "programJoinDate",
                  title: "Days Pending",
                  sortable: true,
                  render: ({ row }) => {
                    if (currentPage === "UPCOMING" || currentPage=== "COMPLETE" ){
                      const days= row?.meetingScheduleDate ? NumberOfDaysBetweenDates([row?.acceptedOn, dateToUnixTimeStamp(row?.createdAt)]) :  NumberOfDaysBetweenDatesAndCurrentDate([
                        dateToUnixTimeStamp(row?.createdAt)
                      ]);
                      if(days>=0){
                        return days
                      }
                      return "-"
                    }
                    else if(currentPage==="PAST" && row.meetingScheduleDate===null){
                      const dates= [row?.date, row?.date2, row?.date3]
                      dates.sort();
                      dates.reverse();
                      const days= NumberOfDaysBetweenDates([dates[0], dateToUnixTimeStamp(row?.createdAt)])
                      if(days>=0){
                        return days
                      }
                      return "-"
                    }
                    return "-";
                  },
                },

                {
                  key: "status",
                  title: "Accepted by",
                  sortable: true,
                  render: ({ row }) => {
                    if (row.status === "ACCEPT")
                      return row.Mentor?.firstName + " " + row?.Mentor?.lastName;
                    // return row.status[0] + row.status.slice(1).toLowerCase();
                  },
                },

                // {
                //   key: "role",
                //   title: "Role",
                //   sortable: true,
                // },

                // {
                //   key: "createDate",
                //   title: "Created On",
                //   sortable: true,
                //   render: ({ value }) => {
                //     return formatDate(value)
                //   }
                // },
                {
                  key: "",
                  title: "Actions",
                  sortable: true,
                  render: ({ row }) => {
                    return (
                      <TableActions>
                        <ButtonSpacer />
                        <Button
                          icon="chat"
                          variant="secondary"
                          onClick={() => {
                            navigate(`/messages/${row?.user?.uuid}`);
                            callAPI(dispatch, "initializeConversation", { id: row?.user?.id });
                          }}
                          
                        />
                        <ButtonSpacer />

                        <Button
                          icon="trash"
                          variant="secondary"
                          onClick={() => setIsDeleteModal(row)}
                        />

                        <ButtonSpacer />
                        <Button
                          text={"View More"}
                          onClick={() => {
                            setIsviewMore(row);
                          }}
                        >
                          View More
                        </Button>

                       { isDeleteModal && (
                          <Modal
                           key={`deleteModal-${row.id}`}
                            title={"Are you sure you want to delete this Meeting"}
                            onClose={() => setIsDeleteModal(false)}
                          >
                            <SectionActions>
                              <Button
                                key={row}
                                icon={isDeleteRequest ? "loading" : "trash"}
                                variant="danger"
                                onClick={() => {
                                  deleteProgram(isDeleteModal);
                                }}
                                text="Yes"
                              />
                              <ButtonSpacer />
                              <Button
                                icon="tick"
                                variant="primary"
                                onClick={() => setIsDeleteModal(false)}
                                text="No"
                              />
                            </SectionActions>
                          </Modal>
                        )}
                      </TableActions>
                    );
                  },
                },
              ]}
              rows={filterParticipant}
            />
          )}
        </Labelize>
        <ButtonSpacer />
        <ButtonSpacer />
        </TableContainerOuter>
      </Body>

      {isViewMore && (
        <Modal
          title={"Meeting Information"}
          width={'90%'}
          actions={[
            {
              text: "Back",
              icon: "back",
              onClick: closeModal,
            },
          ]}
        >
          <MeetingInfo data={isViewMore} />
        </Modal>
      )}
    </ProgramContainer>
  );
}
export default ActivityContent;
